<template>
  <div class="lineGroup">
    <graphTitle :title="'出入场趋势'">
      <el-tooltip placement="top" content="展示今日车辆入场和车辆出场的趋势变化情况">
        <i class="el-icon-question my-icon" />
      </el-tooltip>
    </graphTitle>
    <div id="lineGraph"></div>
  </div>
</template>
<script>
import { dateFormat } from "@/common/js/public";
import graphTitle from "@/components/graphTitle";
export default {
  props: {
    carHourStatics: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      cityNameList: ["入场次数", "出场次数"],
      dateList: [0],
      dataDetailList: [
        { name: "入场次数", type: "line", data: [0] },
        { name: "出场次数", type: "line", data: [0] },
      ],
    };
  },
  methods: {
    drawGraph() {
      let that = this;
      let lineGraph = this.$echarts.init(document.getElementById("lineGraph"));
      let option = {
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
          formatter: (parmas) => {
            console.log(parmas);
            let str = "";
            // if (parmas[0].axisValue == "00:00") {
            //   str += "23:00:00-00:59:59<br/>";
            //   parmas.forEach((e) => {
            //     str += `${e.marker}${e.seriesName}: ${e.value}(次)<br/>`;
            //   });
            // } else {
            str += parmas[0].axisValue + ":00-" + parmas[0].axisValue.split(":")[0] + ":59:59<br/>";
            parmas.forEach((e) => {
              str += `${e.marker}${e.seriesName}: ${e.value}(次)<br/>`;
            });
            // }

            return str;
          },
        },
        legend: {
          data: that.cityNameList,
          orient: "horizontal",
          type: "scroll",
          bottom: -5,
          width: "80%",
          selectedMode: "multiple",
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "10%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: that.dateList,
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
        },
        yAxis: {
          name: "数量​(次)",
          type: "value",
          position: "left",
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
        },
        series: that.dataDetailList,
      };
      lineGraph.setOption(option, true);
    },
  },
  watch: {
    carHourStatics: {
      handler(value) {
        this.dateList = [];
        this.dataDetailList = [];
        let entryNumber = [];
        let exitNumber = [];
        let nowDate = new Date();
        for (var i = 0; i < value.length; i++) {
          nowDate.setHours(value[i].hour);
          nowDate.setMinutes(0);
          this.dateList.push(dateFormat(nowDate, "HH:mm"));
          entryNumber.push(value[i].entryNumber);
          exitNumber.push(value[i].exitNumber);
        }

        this.dataDetailList.push({ name: "入场次数", type: "line", data: entryNumber });
        this.dataDetailList.push({ name: "出场次数", type: "line", data: exitNumber });
        this.drawGraph();
      },
      deep: true,
    },
  },
  mounted() {
    this.drawGraph();
  },
  created() {},
  components: {
    graphTitle,
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.lineGroup
  #lineGraph
    height 400px
  .region
    text-align center
</style>
